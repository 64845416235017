export default class BatchTableItem {
    batchId = 0;
    batchStatus = 0;
    batchName = "";
    batchRemark = "";
    batchStateName = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
